import { toAbsoluteUrl } from "../../../_metronic";
import BetterBox from '../../components/BetterBox';
import BetterAlerts from '../../components/BetterAlerts';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react"
import axios from "axios";
import { useParams } from 'react-router-dom';
import { Constants } from "../../utils/Constants";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import moment from "moment";

export default function TelaFilaAgendamentoIndex(props) {
	const [data, setData] = useState([]);
	const [password, setPassword] = useState(null);
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [nome, setNome] = useState('');

	const { id } = useParams();

	const [showContent, setShowContent] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [logoURL, setLogoURL] = useState('');

	const [barbeiros, setBarbeiros] = useState([]);
	const [agendamentos, setAgendamentos] = useState([]);

	useEffect(() => {
		setLoading(true);

		document.title = "Fila de agendamentos";

		const fetch = async () => {
			await checkContent();
		};

		fetch();

		setLoading(false);
	}, []);

	const checkContent = async () => {
		await axios.get(`${Constants.baseUrl}/fila-agendamentos/${id}`).then(res => {
			if (res.data) {
				setNome(res.data);
			}
		}).catch(error => {
			if (error.response?.status == 404) {
				window.location.href = "/error/error-v6";
				return
			}

			if (error?.response?.data?.errors) {
				Swal.fire('Ops', error.response.data.errors[0], 'error');
			} else {
				Swal.fire('Ops', 'Ocorreu um problema ao buscar os dados. Por favor, tente novamente.', 'error');
			}
		});
	};

	useEffect(() => {
		if (showContent) {
			setLoading(true);
			fetchData();

			setInterval(() => {
				fetchData();
			}, 300000);

			setLoading(false);
		}
	}, [showContent]);

	useEffect(() => {
		distributeAgendamentos();
	}, [agendamentos]);

	const distributeAgendamentos = () => {
		const _barbeiros = barbeiros.map(barbeiro => {
			let _agendamentos = [];

			agendamentos.forEach(agendamento => {
				if (agendamento.id_user === barbeiro.id) {
					_agendamentos.push(agendamento);
				}
			});

			barbeiro.agendamentos = _agendamentos;

			return barbeiro;
		});

		setBarbeiros([..._barbeiros]);
	}

	const onSubmit = () => {
		if (!password) {
			Swal.fire('Ops', 'A senha é obrigatória.', 'error');
			return
		}

		fetchData(true);

	};

	const defineWheterAtendimentoIsHappening = (events) => {
		events.forEach(event => {
			if (moment(event.inicio).isBefore(moment()) && moment(event.fim).isAfter(moment()) && event.cliente_status == 'Chegou') {
				event.acontecendo = true;
			}
		});

		return events;
	};

	const fetchData = async (first) => {
		setSubmitted(true);

		if (first) {
			await axios.post(`${Constants.baseUrl}/fila-agendamentos/${id}`, { password: password, first: true }).then(res => {
				if (first) {
					if (res.data) {
						setLogoURL(res.data.logo.url);
						setBarbeiros(res.data.barbeiros);
						setAgendamentos(defineWheterAtendimentoIsHappening(res.data.agendamentos));
						setShowContent(true);
					}
				}
			}).catch(error => {
				if (error?.response?.data?.errors) {
					Swal.fire('Ops', error.response.data.errors[0], 'error');
				} else {
					Swal.fire('Ops', 'Ocorreu um problema ao buscar os dados. Por favor, tente novamente.', 'error');
				}
			});
		} else {
			await axios.post(`${Constants.baseUrl}/fila-agendamentos/${id}`, { password: password, first: false }).then(res => {
				if (res.data) {
					setAgendamentos(defineWheterAtendimentoIsHappening(res.data.agendamentos));
				}
			}).catch(error => {
				Swal.fire('Ops', 'Ocorreu um problema ao buscar os dados. Por favor, tente novamente.', 'error');
				return
			});
		}

		setSubmitted(false);
	};

	const handleClickShowPassword = () => {
		if (showPassword) {
			setShowPassword(false);
		} else {
			setShowPassword(true);
		}
	};

	return (
		<>
			{showContent ?
				<div style={{ margin: '50px 0px 50px 0px' }}>
					<div className="row" style={{ margin: '0' }}>
						<div className="col-sm-12">
							<div className="row">
								<div className="col-sm-12">
									<center>
										<img alt="Logo" src={logoURL} style={{ width: '128px', height: '128px', marginBottom: '35px', marginRight: '32px' }} />
										<span className="title-fila-agendamentos">Fila de agendamentos</span>
									</center>
								</div>

								<div className="col-sm-12">
									<BetterCircularProgress loading={loading}>
										<div>
											<div className="container-xxl bg-light p-3">
												<div className="row row-barbeiro-fila-agendamentos" style={{ gap: '3px' }}>
													{barbeiros.map(barbeiro => {
														return (<div className="col col-barbeiro-fila-agendamentos">
															<div className="text-center">
																<img src={barbeiro.foto?.url ?? '/images/no-profile.png'} width='104' height='104' className="barbeiro-icon d-block" />
																<span className="barbeiro-name d-block">{barbeiro.usu_name}</span>
															</div>
															<div className="coluna-agendamentos-fila-agendamentos">
																{barbeiro.agendamentos?.length == 0 ?
																	<div className="card-sem-agendamento-fila-agendamentos agenda-vaga">
																		<p>Agenda vaga</p>
																	</div>
																	: null}
																{barbeiro.agendamentos?.length > 0 && !barbeiro.agendamentos?.some(agendamento => agendamento.tipo == 'Agendamento') && !barbeiro.agendamentos?.some(agendamento => agendamento.inicio <= moment(new Date()).format('YYYY-MM-DD HH:mm') && moment(new Date()).format('YYYY-MM-DD HH:mm') <= agendamento.fim )  ?
																	<div className="card-sem-agendamento-fila-agendamentos">
																		<p>Agenda vaga</p>
																	</div>
																	: null}
																{barbeiro.agendamentos.map(agendamento =>
																	<div className={`card-agendamento-fila-agendamentos ${agendamento.cliente_acesso_clube ? 'agendamento-with-plano' : ''} ${agendamento.tipo == 'Bloqueado' ? 'bloqueado' : ''} ${agendamento.tipo == 'Intervalo' ? 'intervalo' : ''}`}>
																		{agendamento.cli_name && <p>{agendamento.acontecendo ? <span><i class="fa fa-cut"></i></span> : null} <span><i class="fa fa-user"></i></span> { agendamento.cli_name}</p>}
																		{agendamento.tipo == 'Bloqueado' ? <p><span><i class="fa fa-times-circle"></i></span> Bloqueado</p> : null}
																		{agendamento.tipo == 'Intervalo' ? <p><span><i class="fa fa-hourglass-half"></i></span> Intervalo</p> : null}
																		<p><span><i class="fa fa-clock"></i></span> { moment(agendamento.inicio).format('HH:mm')} - {moment(agendamento.fim).format('HH:mm')}</p>
																		{agendamento.tipo == 'Agendamento' && agendamento.agendamento_servicos.map(ags =>
																			<p className="servico-name">{ags.servico.ser_nome}</p>
																		)}
																	</div>
																)}
																{barbeiro.agendamentos?.length > 0 && !barbeiro.agendamentos?.some(agendamento => agendamento.tipo == 'Agendamento') ?
																	<div className="card-sem-agendamento-fila-agendamentos">
																		<p>Agenda vaga</p>
																	</div>
																	: null}
															</div>
														</div>)
													}
													)}
												</div>
											</div>
										</div>
									</BetterCircularProgress>
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				<div style={{ margin: '50px 0px 50px 0px' }}>
					<div className="row" style={{ margin: '0' }}>
						<div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
							<div className="row">
								<div className="col-sm-12">
									<center>
										<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ width: '250px', height: 'auto', marginTop: '35px', marginBottom: '35px' }} />
									</center>
								</div>

								<div className="col-sm-12">
									<BetterCircularProgress loading={loading}>
										<div>
											<div className="col-sm-12">
												<BetterAlerts errors={errors} />
											</div>

											<div>
												<div className="col-sm-12">
													<BetterBox title={`Digite a senha para acessar a fila de agendamentos da filial ${nome}.`}>
														<div className="col-sm-12">
															<div className='form-group fg-line'>
																<TextField type={showPassword ? "text" : "password"} name="senha" label="Senha *" margin="normal" variant="outlined"
																	onChange={(event) => setPassword(event.target.value)} InputProps={{
																		endAdornment:
																			<InputAdornment position="end">
																				<IconButton
																					aria-label="toggle password visibility"
																					onClick={handleClickShowPassword}
																				>
																					{showPassword ? <Visibility /> : <VisibilityOff />}
																				</IconButton>
																			</InputAdornment>

																	}}
																/>
															</div>
														</div>
													</BetterBox>
												</div>

												<div className="col-sm-12">
													<center>
														<br />
														<Button disabled={submitted} className="btn btn-primary btn-lg btn-bold" onClick={() => onSubmit()}>
															<i className={`margin-icon ${submitted ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>Enviar
														</Button>
													</center>
												</div>
											</div>
										</div>
									</BetterCircularProgress>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>

	)
}